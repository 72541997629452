<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 11:48:11
 * @LastEditTime: 2023-04-11 16:52:42
 * @Descripttion: 【互动中心-焦点关注】详情
-->
<style lang="scss" scoped>
.news-detail {
    @include innerPage;
    @include pageHead(-42px);

    .detail {
        &-inner {
            width: 100%;
            height: calc(100% - 236px);
            box-sizing: border-box;
            padding-left: 12px;
            padding-right: 34px;
            margin-top: 34px;
            margin-bottom: 40px;
            overflow: hidden;
            overflow-y: auto;

            h3 {
                line-height: 38px;
                color: #232323;
                text-align: center;
            }

            .other-info {
                height: 30px;
                font-size: 16px;
                color: #696969;
                margin-top: 8px;
                @include flexBox(center);

                span {
                    margin: 0 20px;
                }
            }
        }

        &--abstract {
            width: 1046px;
            margin: 20px auto 0;
            font-size: 16px;
            line-height: 30px;
            text-align: justify;
            color: #434444;
        }

        &--rich {
            width: 1046px;
            margin: 20px auto 0;

            ::v-deep {
                p {
                    font-size: 16px;
                    line-height: 30px;
                    text-align: justify;
                    color: #434444;
                }

                img {
                    display: block;
                    max-width: 100%;
                    border-radius: 10px;
                    margin: 36px auto;
                }
            }
        }
    }
}
</style>

<template>
    <section class="news-detail">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb />
                <el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
            </div>
        </div>
        <div class="detail-inner">
            <h3 class="bold">{{ detailData.plinf_title }}</h3>
            <div class="other-info pf_medium">
                <span>{{ detailData.create_time }}</span>
                <span>阅读量：{{ detailData.plinf_read_num || 0 }}次</span>
                <span>点赞量：{{ detailData.plinf_praise_num || 0 }}次</span>
            </div>
            <p class="detail--abstract" v-if="detailData.plinf_precis">摘要：{{ detailData.plinf_precis }}</p>
            <div class="detail--rich" v-html="detailData.plinf_content"></div>
        </div>
    </section>
</template>

<script>
import { $newsDetail } from "@api/interaction"
import { formatFile } from "@utils";
export default {
    name: 'interaction_newsDetail',
    computed: {
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        },
    },
    data() {
        return {
            detailData: {},
        }
    },
    created() {
        this.getDetail();
    },
    methods: {
        /** 获取资讯详情 */
        async getDetail() {
            let { data } = await $newsDetail(this.$route.params.id);
            this.detailData = data;
        }
    },
}
</script>